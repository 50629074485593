
footer {
    background-color: $white;
    // padding         : 75px 0px 35px;
  
    ul {
      margin         : 0;
      padding        : 0;
      list-style-type: none;
  
      li {
        a {
          font-weight: 300;
          font-size  : 16px;
          line-height: 28px;
          color      : $black;
        }
      }
    }
  
    .sub-footer {
      margin-top     : 60px;
      display        : flex;
      justify-content: space-between;
      align-items    : center;
  
      a {
        font-weight: 300;
        font-size  : 14px;
        line-height: 21px;
        color      : $black;
      }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {
}

// Desktops
@media (max-width: 1200px) {
}

// Tablets
@media (max-width: 960px) {
}

// Mobile devices
@media (max-width: 640px) {
    footer {
        background-color: $white;
        padding: 0;
    
        .sub-footer {
          margin-top: 30px;
          flex-flow: column;
          align-items: flex-start;
        }
    }
}