.input-errors {
    p {
        color: $danger-color;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

.custom-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    color: $primary;

    input[type='checkbox'] {
        width: 16px;
        height: 16px;
        background-color: transparent;
        border: 1px solid $border;
        box-shadow: none;
        flex: 0 0 20px;
        margin-right: 10px;
        margin-top: 0px;
        margin-bottom: 0px;

        &:checked {
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23D1D3D4' xmlns='http://www.w3.org/2000/svg' height='11' viewBox='0 0 14 11' width='14'%3E%3Cpath d='M11.979 0L14 1.991l-8.413 8.554L0 5.269l1.946-2.064 3.568 3.369z'/%3E%3C/svg%3E");
            background-size: 12px 10px;
        }

        &[disabled] {
            background-color: $grey-disabled;
            border: 1px solid $grey-disabled;
        }
    }

    div {
        flex: 1 0 auto;
    }

    a {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: $primary;
        text-decoration: underline;
        margin: 0;
        display: initial;

        &:hover,
        &:active,
        &:focus {
            color: darken($primary, 8%);
        }
    }

    &.uk-form-danger {
        color: $danger-color;

        input[type='checkbox'] {
            border: 2px solid $danger-color;
        }
    }

    &.disabled {
        p {
            color: $grey-disabled;
        }

        &:hover {
            cursor: no-drop;
        }

        input[type='checkbox'] {
            &:hover {
                cursor: no-drop;
            }
        }
    }

    &--big {
        input[type='checkbox'] {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;

            &:checked {
                background-image: url("/dist/assets/images/icons/check.svg");
                background-size: 24px 20px;
            }
        }
    }
}

.custom-custom-checkbox.disabled {
    &.opacity-50 {
        &:focus+label {
            opacity: 50%;
        }
    }

    &:focus+label {
        opacity: 50% !important;
    }

    display: none;
    position: absolute;



}

.custom-custom-checkbox-label {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 5px;

    &.disabled {
        .checkmark {
            background-color: $white;
        }
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        border: 1px solid $black;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 25%;
            top: 50%;
            width: 4px;
            height: 7px;
            border: 1px solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg) translate(-50%, -50%);
            -ms-transform: rotate(45deg) translate(-50%, -50%);
            transform: rotate(45deg) translate(-50%, -50%);
        }

    }

    .text {
        padding-left: 30px;
    }

    input {
        position: absolute;
        opacity: 0 !important;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &:hover input~.checkmark {
        background-color: $primary;
    }

    input:checked~.checkmark {
        background-color: $primary;
        border-color: $primary;

        &:after {
            display: block;
        }
    }

}

.custom-radio {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

    input[type='radio'] {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        margin-right: 10px;
        margin-top: 2px;

        background-color: transparent;
        border: 1px solid $border;

        &:checked {
            border: 1px solid $primary;
            background-color: $primary;
            background-size: 150%;
        }
    }
}



.uk-input,
.uk-textarea {
    height: 35px;
    background-color: transparent;
    border-bottom: 1px solid $border;
    border-radius: 0;
    padding: 0px;

    font-family: georgiapro, sans-serif;

    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $primary !important;

    &:focus {
        border-bottom: 1px solid $primary;
        outline: none;
        background-color: transparent;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $primary;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $primary;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $primary;
    }

    /* Change the white to any color */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $black-dark inset !important;
        -webkit-text-fill-color: $primary !important;

    }

    &.uk-form-danger {
        border: 2px solid $danger-color;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $danger-color;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $danger-color;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $danger-color;
        }
    }
}

.uk-textarea {
    padding: 0px;
    height: auto;
}

.uk-form-label {
    font-family: $ff-stack;
    font-size: 12px;
    color: $black;
    margin-bottom: 0px !important;
    display: block;
    opacity: 0;
    transition: all .3s ease;
    text-transform: uppercase;

    &-privacy {
        opacity: 1;
        margin-bottom: 10px !important;
        font-size: 14px;
        text-transform: none;

        a {
            text-decoration: underline;
        }
    }
}

input[type="text"],
input[type="email"],
input:not([value=""]),
textarea {
    &:focus+label {
        opacity: 1;
        transform: translateY(-4px);
    }
}

form .form-url {
    display: none;
}

form.form-cart {

    .uk-input,
    .uk-textarea {

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px $white inset !important;
            -webkit-text-fill-color: $primary !important;

        }
    }
}

select.custom-select {
    padding: 0px;
    height: 35px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $border;
    font-family: georgiapro, sans-serif;
    background-color: transparent;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $primary;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: url(/dist/assets/images/icons/angle-down.svg) no-repeat 100% #fff;
    background-position: right 20px center;

    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        border-bottom: 1px solid $primary;
    }
}

form.form-custom.form-custom-white {

    .uk-input,
    .uk-textarea {

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px $white inset !important;
            -webkit-text-fill-color: $primary !important;

        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}