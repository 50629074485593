.cta {
  * {
    color: $white !important;
  }

  .card__image {
    background-position: right bottom !important;
    background-size: contain !important;
  }

  &--img-bttm {
    padding-bottom: 200px;

    .card__image {
      position: absolute;
      height: 200px;
      right: 0 !important;
      width: 100% !important;
      bottom: 0;
    }

    .pr-4 {
      padding-right: 0 !important;
    }
  }

  &--large {
    background-color: $secondary;
    padding: 60px;
  }

  position: relative;

  p {
    font-size: 14px;
    line-height: 26px;
    width: 60%;
  }

  &-content {
    z-index: 5;
  }

  &::after {
    content: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: rgba(177, 92, 64, .15);
    z-index: 1;
  }

  .uk-button-primary {
    border: 1px solid $white;

    &::after {
      background: $white;
    }

    &:hover {
      color: $primary !important;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
  .cta p {
    width: 75%;
  }
}