// ==========[ VERY LARGE DESKTOPS ]==========

@media (max-width: 1600px) {}

// ==========[ DESKTOPS ]==========

@media (max-width: 1200px) {}

// ==========[ TABLETS ]==========

@media (max-width: 960px) {
  .modal {
    padding: 60px 0;

    &__box {
      max-width: 95vw;
      padding: 30px;
    }
  }

  .modal--big {

    .modal__box {
      width: 95%;
    }

    .product-row {
      display: initial;

      .image-block {
        padding-top: 50%;
        width: 100%;
        margin: 0 auto;
      }

    }
  }

}

// ==========[ MOBILE DEVICES ]==========

@media (min-width: 640px) {
  .uk-flex\@s {
    display: flex !important;
  }


}

@media (max-width: 640px) {
  .modal--big {
    padding: 40px 0;

    .modal--title {
      font-size: 2rem;
      max-width: 100%;
    }

    .product-added-wrap {
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
    }
  }

  .uk-section-extern {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 50px;
  }

  .full-left-image,
  .full-right-image {
    position: relative;
    right: 0;
    left: 0;
    height: 400px;
    margin-right: 12px;
    margin-left: 12px;
  }
}