.uk-button-cart {
  background-color: $black;
  color: $white;
  font-family: $proxima-nova !important;
  font-size: 18px !important;
  padding: 16px 24px !important;

  &:hover {
    background-color: #58595b;
  }
}

.uk-button-back {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $primary;

  &:hover {
    text-decoration: none;
    color: #000;
  }
}

.big-button {
  padding: 45px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  border: 1px solid $border;

  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
  color: $black;

  &:hover {
    text-decoration: none;
    border: 1px solid $primary;
    background-color: $secondary;
    color: $primary;
  }

  &.uk-active {
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}