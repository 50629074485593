.uk-link {
    position: relative;
    padding-right: 20px;

    span {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $primary;
        transition: color 0.3s ease;
    }

    i,
    svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.4s ease, color 0.3s ease;
        color: $primary;
        font-size: 14px;
    }

    &:hover {
        text-decoration: none;

        span,
        i,
        svg {
            color: darken($color: $primary, $amount: 6%);
        }

        i,
        svg {
            transform: translateX(6px) translateY(-50%);
        }
    }

    &--black {

        span,
        i,
        svg {
            color: $black;
        }
    }
}

.uk-button {
    font-size: 16px;
    line-height: 24px;
    padding: 14px 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all .4s ease-in-out;
    font-family: georgiapro, sans-serif;
}

.uk-button-primary {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
    text-decoration: none !important;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
        background: $primary;
        bottom: 100%;
        transform: translateY(200%);
        transition: all .3s ease-in-out;

    }

    &:hover {
        background-color: transparent;
        color: $white !important;

        &::after {
            transform: translateY(100%);
            transition: all .3s ease-in-out;

        }
    }
}

.uk-button-secondary {
    background-color: $secondary;
    color: $primary;

    &:hover {
        background-color: darken($color: $primary, $amount: 6%);
        color: $white;
    }
}
.uk-button-tertiary {
    background-color: transparent;
    padding:0px;
    color: $primary;
    font-weight: bolder;

    &:hover {
        color: $black;
    }
}

.button-icon {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    color: $white;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;

    &--large {
        width: 120px;
        height: 120px;
        font-size: 21px;
    }

    &:hover {
        background-color: $secondary;
        color: $primary;
        box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
    }
}

.block-link {
    position: relative;
    background-color: $secondary;
    display: block;
    width: 100%;
    padding-top: 75%;
    border-radius: $border-radius;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    span {
        position: absolute;
        left: 25px;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);

        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $black;
        transition: color 0.3s ease;
    }

    &:hover {
        background-color: $primary;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

        span {
            color: $white;
        }
    }
}

.block-image-link {
    position: relative;
    background-color: $secondary;
    display: block;
    width: 100%;
    padding-top: 75%;
    border-radius: $border-radius;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparentize($color: $black, $amount: 0.7);
        transition: background-color 0.3s ease;
    }

    span {
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;

        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $white;
        transition: color 0.3s ease;
    }

    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

        .overlay {
            background-color: transparentize($color: $primary, $amount: 0.3);
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}