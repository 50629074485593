/* Fonts
-------------------------------------------------- */
$proxima-nova: "proxima-nova", sans-serif;

$georgia: "georgiapro", sans-serif;


$ff-stack: $proxima-nova;



/* Colors
-------------------------------------------------- */
$white: #ffffff;
$grey-light: #d1d3d4;
$grey: #e8e8e8;
$grey-dark: #666666;
$grey-disabled: #b7b2b2;
$black: #414042;
$black-dark: #1d1d1e;


$primary: #b15c40;
$secondary: #d3aea2;

$background:#f1f2f2;

$text: $black;
$border: $grey;

$danger-color: #ff2424;
$danger-color-light: lighten($danger-color, 70%);
$warning-color: #ff6601;
$success-color: #1eaf3a;

$cookie-button-color: $white;
$cookie-button-bgcolor: $primary;
$cookie-link-color: $black;

/* Border radius
-------------------------------------------------- */
$border-radius: 3px;

/* Shadows
-------------------------------------------------- */
$shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
$shadow2: 0px 5px 15px rgba(0, 0, 0, 0.2);

/* Mixin's
-------------------------------------------------- */
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin fix-bttn {
  display: inline-block;
  text-align: center;

  &:after {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}


/* Functions
-------------------------------------------------- */