/* General
-------------------------------------------------- */

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}



#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.body-bg {
  width: 100vw;
  height: 100vh;
  z-index: -9999999;
  background-image: url('/dist/assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.label_matrix {
  text-align: right;
  font-size: 15px;
  font-style: italic;
  padding: 5px;
  position: absolute;
  right: 15px;
  z-index: 999;
}

.uk-section {
  padding-top: 60px;
  padding-bottom: 60px;

  &-large {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  &-xlarge {
    padding-top: 250px;
    padding-bottom: 150px;
  }
}


.uk-section-extern {
  margin-top: 100px;
  margin-bottom: 100px;
  padding-top: 50px;
}

.colored {
  background-color: $black-dark;

  * {
    color: $grey-light !important;
  }
}



// ==========[ LAZYSIZES ]==========

// lazysizes
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.lazyload-wrapper {
  position: relative;

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

// TER INFO: bijbehorende HTML:
// Voor img tag:
// <div class="lazyload-wrapper" style="padding-bottom: <?= getImageRatio($page->image) ?>%;">
//   <img data-src="<?= $page->image->size(1600, 1600, ['cropping' => false])->url ?>" alt="<?= $page->image->description ?>" class="lazyload">
// </div>
// Voor backgroundimage:
// <div class="lazyload" data-bgset="<?= $page->image->size(2400, 2400, ['cropping' => false])->url ?>"></div>


/* Product detail page
-------------------------------------------------- */

.cart-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}




::-moz-selection {
  /* Code for Firefox */
  color: $white;
  background: $primary;
}

::selection {
  color: $white;
  background: $primary;
}



body {
  position: relative;
}

.language-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;

  a {
    text-transform: uppercase;
    color: $white;
    font-size: 12px;
    transition: all .2s;

    &:hover {
      text-decoration: none;
      color: $grey;
    }
  }

  .language-active {
    font-weight: 600;
  }
}

.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-image,
.page-image {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  // margin-top: -140px;

  &-container {
    height: 100vh;
    width: 100vw;
    position: relative;
  }


  &-stamp {
    position: absolute;
    bottom: -50px;
    right: 150px;
  }

  &::after {
    content: "";
    display: flex;
    height: 50%;
    width: 100%;
    background: linear-gradient(-180deg, rgba(0, 0, 0, .2), transparent);
  }
}

.page-image-container {
  height: 25vh;
}

.login {
  &-image {
    width: 66.66%;
    height: 100vh;
    background-color: black;
    position: relative;

    &-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      h1 {
        color: $white;
      }

      &-stamp {
        position: absolute;
        z-index: 20;
        top: 20px;
        right: -50px;
      }
    }
  }

  &-form {
    width: 33.34%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px;

    &-container {
      width: 100%;
    }

    input {
      background-color: $background;
      border-bottom: 1px solid #e6e7e8;
      border-right: 1px solid #e6e7e8;
      height: 48px;
      color: #6d6e71 !important;
      padding: 8px 16px;
      font-family: 'proxima-nova', sans-serif;

      &:focus {
        background-color: $background;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #6d6e71;
        opacity: 1;
        /* Firefox */
      }

      /* Change the white to any color */
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $background inset !important;
        -webkit-text-fill-color: #6d6e71 !important;

      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #6d6e71;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #6d6e71;
      }
    }

    .uk-button-primary {
      background-color: $black-dark;
      border-color: $black-dark;
      color: $white;
      font-family: 'proxima-nova', sans-serif;
      transition: .2s;

      &:hover {
        background-color: #58595b;
        border-color: #58595b;
      }

      &::after {
        content: none;
      }
    }

    a {
      color: $black;
      text-decoration: underline;
      transition: .2s;
      font-weight: 500;
      margin-top: 48px;

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }

    .password {
      &-stripe {
        line-height: 24px;
      }

      &-link {
        color: $black;
      }
    }


  }
}

.hide {
  display: none !important;
}

.anchortag {
  scroll-margin-top: 70px;
}




.contactform {
  .form {
    // width: 50%;
    margin-left: 100px;

    p {
      font-size: 14px;
      line-height: 25px;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
  .page-image-container {
    height: 15vh;
  }

  .login-image {
    width: 50%;
  }

  .login-form {
    width: 50%;
  }
}

// Mobile devices
@media (max-width: 640px) {
  .login-image {
    width: 100%;
    height: 25vh;
  }

  .login-form {
    width: 100vw;
    padding: 48px 24px;
  }

  .login-image-content-stamp {
    width: 150px;
  }

  .page-image-container {
    height: 20vh;
  }

  .page-image-stamp {
    width: 150px;
    bottom: -35px;
    right: 25px;
  }

  .contactform .form {
    margin-left: 0;
    padding: 0 24px 48px 24px;
  }

  #map {
    padding-top: 60%;
  }

  .uk-section-xlarge {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}