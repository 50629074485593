.cart {
    overflow: hidden;

    &__row {
        border-bottom: 1px solid $border;
        padding: 15px 0;
        display: grid;
        grid-template-columns: 100px 2fr 1fr 1fr;
        grid-template-areas: "remove link amount price";
        column-gap: 10px;
        row-gap: 10px;

        &__noremove {
            grid-template-columns: 2fr 1fr 1fr;
            grid-template-areas: "link amount price";
        }

        &:last-of-type {
            border-bottom: 0;
        }

        // Cart gird area
        .cga-remove {
            grid-area: remove;

            // positioning content container
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .cga-link {
            grid-area: link;
        }

        .cga-amount {
            grid-area: amount;

            // positioning content container
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .cga-price {
            grid-area: price;

            // positioning content container
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
    }

    &__remove {
        border: 0;
        background-color: transparent;
        padding: 0;
        font-size: 21px;
        color: $grey;
        cursor: pointer;

        &:hover {
            color: $danger-color;
        }
    }

    &__image {
        position: relative;
        width: 200px;
    }

    &__product-info {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h1,
        .uk-h1,
        h2,
        .uk-h2,
        h3,
        .uk-h3,
        h4,
        .uk-h4,
        h5,
        .uk-h5,
        p,
        .cms,
        .text {
            transition: all .3s ease;
            color: $text;
        }
    }

    &__link {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;

            .cart__product-info {

                h1,
                .uk-h1,
                h2,
                .uk-h2,
                h3,
                .uk-h3,
                h4,
                .uk-h4,
                h5,
                .uk-h5,
                p,
                .cms,
                .text {
                    color: $primary;
                }
            }
        }

        &.no-hover {

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;

                .cart__product-info {

                    h1,
                    .uk-h1,
                    h2,
                    .uk-h2,
                    h3,
                    .uk-h3,
                    h4,
                    .uk-h4,
                    h5,
                    .uk-h5,
                    p,
                    .cms,
                    .text {
                        color: $text;
                    }
                }
            }
        }
    }

    &__amount {
        height: 50px;
        width: 65px;
        border: 1px solid $border;
        color: $black;
        border-radius: 3px;

        font-family: $ff-stack;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $black;

        &--danger {
            border: 1px solid $danger-color;
            color: $danger-color;
        }

        &:focus {
            border: 1px solid $primary;
            outline: none;
        }
    }

    &__prices {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;

        &--current {
            margin: 0 10px 0 0;
            font-family: $ff-stack;
            font-size: 24px;
            line-height: 28px;
            font-weight: 700;
            color: $text;
        }

        &--label {
            margin: 0 10px 0 0;
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
            color: $text;
        }

        &--old {
            margin: 0 10px 0 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: $text;
            text-decoration: line-through;
        }
    }

    &__footer {
        padding: 60px 0;

        select {
            width: 270px;
        }
    }

    &__totals {
        display: grid;
        grid-template-columns: 3.2fr 1.3fr;
        gap: 12px 30px;

        &__label {
            text-align: right;
            margin: 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            &.total {
                font-weight: 700;
                font-size: 24px;
                line-height: 28.8px;
                color: $primary;
            }
        }

        &__price {
            text-align: right;
            font-family: $ff-stack;
            font-weight: 700;
            font-size: 24px;
            line-height: 28.8px;
            color: $text;
            margin: 0;
            white-space: nowrap;

            &.delivery-cost {
                color: $primary;
            }

            &.total {
                font-size: 32px;
                line-height: 38px;
                color: $primary;
            }
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {
    .cart {
        &__totals {
            grid-template-columns: 2.5fr 2fr;
        }
    }
}

// Tablets
@media (max-width: 960px) {
    .cart {
        &__row {
            grid-template-columns: 40px 3fr 1fr 1fr;

            &__noremove {
                grid-template-columns: 2fr 1fr 1fr;
                grid-template-areas: "link amount price";
            }
        }

        &__totals {
            grid-template-columns: 1fr 1fr;

            h3 {
                padding-right: 10px;
            }
        }

        &__image {
            width: 100px;
        }
    }
}

// Mobile devices
@media (max-width: 640px) {
    .cart {
        &__row {
            grid-template-columns: 40px 1fr 1fr;
            grid-template-areas:
                "remove link link"
                "remove amount price";
            row-gap: 20px;

            &__noremove {
                grid-template-columns: 2fr 2fr;
                grid-template-areas:
                    " link link"
                    " amount price";
            }
        }

        &__footer {
            padding: 20px 0;
        }

        &__totals {
            grid-template-columns: 1.5fr 1fr;
            gap: 16px 30px;

            h3 {
                padding-right: 0px;
            }
        }
    }
}