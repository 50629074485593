.top-bar {
  background-color: transparent;
  padding: 100px 0 0 0;
  transition: padding .3s ease-out;


  // .uk-container {
  //   height: 40px;
  // }
  &-container {
    position: fixed;
    width: 100%;
    z-index: 200;
  }

  &-animation {
    .top-bar {
      padding: 20px 0;
      background-color: $white;

      a {
        color: $black;

        &:after {
          content: '';
          background-color: $black;
        }

        &.uk-button-primary {
          background-color: transparent;
          color: $primary;
          border: 1px solid $primary
        }
      }

      svg,
      svg>path {
        fill: $black;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
    margin: 0 80px 0 0;
    padding: 0;


    >li {
      margin: 0px 10px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      >a {
        color: $white;
        font-size: 16px;
        line-height: 21px;
        display: inline-block;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 1px;
          bottom: -2px;
          left: 0;
          background-color: $white;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        &.uk-button-primary {
          background-color: $primary;
          color: $white;

          &:hover {
            background-color: darken($color: $primary, $amount: 10%);
          }
        }

        &:hover {
          text-decoration: none;

          &:after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }
    }

    &-left {
      width: 100%;
      display: flex;
      justify-content: end;
    }

    &-right {
      width: 100%;
      display: flex;
      justify-content: start;
    }
  }

  &__menu {
    i {
      color: $white;
      font-size: 20px;
    }

    display: none;

    margin-top: 30px;
  }

  &__logo {
    margin: 0 80px;
    min-width: 250px;
    display: flex;
    justify-content: center;

    svg,
    svg>path {
      fill: $white;
    }
  }

  &-dark {
    .top-bar {
      padding: 40px 0;
      background-color: $white;

      svg,
      svg>path {
        fill: $black;
      }

      &__menu {
        i {
          color: $black;
          font-size: 20px;
        }
        margin-top: 30px;
      }

      &__nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style-type: none;
        margin: 0 80px 0 0;
        padding: 0;


        >li {
          margin: 0px 10px;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }

          >a {
            color: $black;
            font-size: 16px;
            line-height: 21px;
            display: inline-block;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              width: 100%;
              transform: scaleX(0);
              height: 1px;
              bottom: -2px;
              left: 0;
              background-color: $white;
              transform-origin: bottom right;
              transition: transform 0.25s ease-out;
            }

            &.uk-button-primary {
              background-color: $primary;
              color: $white;

              &:hover {
                background-color: darken($color: $primary, $amount: 10%);
              }
            }

            &:hover {
              text-decoration: none;

              &:after {
                transform: scaleX(1);
                transform-origin: bottom left;
              }
            }
          }
        }

        &-left {
          width: 100%;
          display: flex;
          justify-content: end;
        }

        &-right {
          width: 100%;
          display: flex;
          justify-content: start;
        }
      }
    }


  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
  .top-bar {
    padding: 30px 0 0 0;

    &__nav {
      margin-right: 0;

      li {
        margin-top: 10px;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  .top-bar__menu {
    display: flex;
  }


}

// Mobile devices
@media (max-width: 640px) {
  .top-bar {
    padding-top: 50px;

    &__logo {
      max-width: 230px;
    }
  }

  .top-bar-animation {
    .top-bar__nav {
      display: none;
    }
  }
  .top-bar-dark .top-bar {
    padding: 20px 0px;
  }
}