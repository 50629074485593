.product {
  display: flex;
  flex-flow: column;
  // justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: $background;
  position: relative;

  &::before {
    background-color: rgba(29, 29, 30, .9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    z-index: 5;
    transition: .2s ease-in-out;
  }

  &__header {
    width: 100%;
    position: relative;
  }

  &__image {
    background-size: contain;
    padding-bottom: 100%;
    background-position: 50%;
    background-repeat: no-repeat;

    &--container {
      padding: 32px;
    }
  }

  &__like {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $primary;
    cursor: pointer;
    background-color: $white;
    border-radius: 3px;
    border: 0;
    padding: 6px;

    >.normal {
      display: block;
    }

    >.active {
      display: none;
    }

    &:hover,
    &--liked {
      >.normal {
        display: none;
      }

      >.active {
        display: block;
      }
    }
  }

  &__info {
    // margin-top: 30px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    z-index: 20;
    border-top: 1px solid #c2c4c6;
    width: 100%;
    padding: 16px 24px;
    font-family: $georgia;

    .body--small {
      font-size: 9px;
      font-style: italic;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: $black;
    font-family: $proxima-nova;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__price {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    &--label {
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
      color: $black;
      margin: 0 10px 0 0;
    }

    &--current {
      font-weight: bold;
      font-size: 24px;
      line-height: 28.8px;
      color: $black;
      margin: 0 10px 0 0;
    }

    &--old {
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      color: $black;
      text-decoration: line-through;
      margin: 0;
    }
  }

  &__cart {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    background-color: $primary;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    margin-left: 20px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: darken($color: $primary, $amount: 4%);
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;




    .product__info {
      border-top: 1px solid $white;

      .product__title,
      .body--small {
        color: $white;
      }

    }

    &::before {
      opacity: 1;
    }
  }
}

.product__stock {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon {
    color: $white;
    font-size: 14px;
    margin-right: 10px;
  }

  p {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  &--success {
    .icon {
      color: $success-color;
    }

    p {
      color: $success-color;
    }
  }

  &--warning {
    .icon {
      color: $warning-color;
    }

    p {
      color: $warning-color;
    }
  }

  &--error {
    .icon {
      color: $danger-color;
    }

    p {
      color: $danger-color;
    }
  }
}

.productdetail {
  &__title {
    font-family: $proxima-nova;
    font-weight: 400;
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}